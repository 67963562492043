<template>
    <div>

        <!-- Header-Image Section -->
        <section class="header-image">
            <div class="container text-center">
                <h1 class="text-white">PharmaConnect | Qui Sommes-Nous ?</h1>
            </div>

        </section>

        <!-- Introduction Section -->
        <section class="introduction text-center py-5">
            <div class="container">
                <h2>C'est Quoi PharmaConnect ?</h2>
                <br>
                <hr>
                <p class="about-us-paragraph">PharmaConnect est bien plus qu'une simple plateforme. C'est une solution
                    révolutionnaire
                    qui
                    connecte les pharmaciens, les grossistes et les fournisseurs de produits pharmaceutiques et
                    parapharmaceutiques en Tunisie. Nous facilitons la communication, la gestion des commandes et
                    l'accès à des
                    informations précieuses du secteur.
                </p>
                <br>
                <p class="about-us-paragraph">
                    Pour les pharmaciens, PharmaConnect offre une solution simple et efficace pour passer des commandes
                    auprès de multiples grossistes, consulter les circulaires, suivre les factures et gérer les stocks,
                    le tout depuis une seule interface. Cela permet de gagner du temps, d'éviter les erreurs et de
                    garantir une disponibilité constante des produits essentiels.
                </p>
                <br>
                <p class="about-us-paragraph">
                    Pour les grossistes, PharmaConnect fournit une plateforme centralisée pour gérer les commandes
                    provenant de pharmacies, suivre les ventes et analyser les données de marché en temps réel. Cela
                    aide à optimiser les niveaux de stock, améliorer les prévisions de demande et renforcer les
                    relations avec les clients pharmaciens.
                </p>
                <br>
                <p class="about-us-paragraph">
                    Pour les fournisseurs, PharmaConnect permet une visibilité complète sur la distribution de leurs
                    produits. Les fournisseurs peuvent ajouter, modifier et suivre leurs articles, analyser les ventes
                    en temps réel et ajuster leurs stratégies de commercialisation pour répondre aux besoins du marché.
                    Cela permet d'améliorer la performance des ventes et d'assurer une meilleure disponibilité des
                    produits sur le marché.
                </p>
            </div>
        </section>

    </div>


</template>

<script>


export default {
    metaInfo() {
        return {
            title: 'PharmaConnect | À Propos de PharmaConnect - Qui Sommes-Nous ?',
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content: "Une solution innovante qui connecte les pharmaciens, les grossistes et les fournisseurs en Tunisie pour simplifier l'accès à des informations précieuses du secteur."
                },
                {
                    name: 'keywords',
                    content: 'PharmaConnect, Pharmacie, Grossistes, Fournisseurs, Tunisie, Pharmaciens, Plateforme Pharmaceutique, parapharmacie,parapharmaceutique, Commandes en Ligne'
                },
                {
                    name: 'author',
                    content: 'DIGITHINK CONSULTING'
                },
                {
                    property: 'og:title',
                    content: 'PharmaConnect | À Propos de PharmaConnect - Qui Sommes-Nous ?'
                },
                {
                    property: 'og:description',
                    content: "Découvrez PharmaConnect - une solution innovante qui connecte les pharmaciens, les grossistes et les fournisseurs en Tunisie pour simplifier la chaîne d'approvisionnement pharmaceutique et améliorer l'efficacité des services. Nous facilitons la communication, la gestion des commandes et l'accès à des informations précieuses du secteur."
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/about",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "PharmaConnect | À Propos de PharmaConnect - Qui Sommes-Nous ?",
                },
                {
                    name: "twitter:description",
                    content:
                        "Découvrez PharmaConnect - une solution innovante qui connecte les pharmaciens, les grossistes et les fournisseurs en Tunisie pour simplifier la chaîne d'approvisionnement pharmaceutique et améliorer l'efficacité des services. Nous facilitons la communication, la gestion des commandes et l'accès à des informations précieuses du secteur."
                },
                {
                    name: "twitter:image",
                    content: require('@/assets/images/logo/logo.svg'),
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/about",
                },
            ],
            script: [
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "PharmaConnect | À Propos de PharmaConnect - Qui Sommes-Nous ?",
                        "description": "Découvrez PharmaConnect - une solution innovante qui connecte les pharmaciens, les grossistes et les fournisseurs en Tunisie pour simplifier la chaîne d'approvisionnement pharmaceutique et améliorer l'efficacité des services. Nous facilitons la communication, la gestion des commandes et l'accès à des informations précieuses du secteur.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },
};
</script>

<style scoped>
.header-image {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('~@/assets/images/website/page-header-bg.jpg') no-repeat center center;
    background-size: cover;
    color: white;
    padding: 50px 0;
}

.about-us-paragraph {
    font-size: 1.14rem;
    line-height: 2.5rem;
    text-align: left;
}
</style>